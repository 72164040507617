(function() {
  'use strict';

  angular
    .module('noConnection')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('noConnection', {
        url: '/no-connection',
        views: {
          'mainView': {
            templateUrl: 'no-connection/no-connection.tpl.html',
            controller: 'NoConnectionCtrl',
            controllerAs: 'noConnection'
          },
          'layoutView': {

          }
        },
        data: {
          mainViewClasses: 'no-margin'
        }

      });
  }
}());
